<template>
  <div class="wrap">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="productId" label="商品编号" prop="productId">
        <a-input v-model="form.productId" placeholder="请填写商品编号" />
      </a-form-model-item>
      <a-form-model-item ref="cusName" label="用户姓名" prop="cusName">
        <a-input v-model="form.cusName" placeholder="请填写用户姓名" />
      </a-form-model-item>
      <a-form-model-item ref="cusCertNo" label="客户身份证号" prop="cusCertNo">
        <a-input v-model="form.cusCertNo" placeholder="请填写身份证号" />
      </a-form-model-item>
      <a-form-model-item ref="cusContactPhone" label="客户联系电话" prop="cusContactPhone">
        <a-input v-model="form.cusContactPhone" placeholder="请填写联系电话" />
      </a-form-model-item>
      <a-form-model-item ref="harvestaddress" label="收货地址" prop="harvestaddress">
        <a-cascader v-model="form.harvestaddress" :field-names="{ label: 'value', value: 'value', children: 'children' }" :options="options" placeholder="请选择收货地址" />
      </a-form-model-item>
      <a-form-model-item ref="address" label="详细地址" prop="address">
        <a-input v-model="form.address" placeholder="请填写详细地址" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 10, offset: 3 }">
        <a-input v-model="form.extend1" placeholder="申请电信号码时，传入电信js指纹" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 10, offset: 3 }">
        <a-input v-model="form.extend2" placeholder="申请移动选号时传入号码归属省" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 10, offset: 3 }">
        <a-button :loading="buttonloading" type="primary" @click="onSubmit">
          下单
        </a-button>
        <a-button :loading="buttonloading" style="margin-left: 10px;" @click="resetForm">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-alert v-if="order != ''" style="width: 500px;right: 100px;position: absolute;top:150px" message="订单号" :description="order" type="success" show-icon />
    <a-alert v-if="extend1 != ''" style="width: 500px;right: 100px;position: absolute;top:200px" message="优惠充值链接" :description="extend1" type="success" show-icon />
  </div>
</template>
<script>
import adressData from '../../../public/adress.json'
export default {
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 10 },
      buttonloading: false,
      form: {
        productId: '',
        harvestaddress: undefined,
        address: '',
        cusName: '',
        cusCertNo: '',
        cusContactPhone: '',
        province: '',
        city: '',
        district: '',
        extend1: '',
        extend2: ''
      },
      options: adressData,
      rules: {
        address: [{ required: true, message: '请填写详细地址', trigger: 'blur' }],
        cusContactPhone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
        cusName: [{ required: true, message: '请填写用户姓名', trigger: 'blur' }],
        cusCertNo: [{ required: true, message: '请填写身份证号', trigger: 'blur' }],
        productId: [{ required: true, message: '请填写商品编号', trigger: 'blur' }],
        harvestaddress: [{ required: true, message: '请选择收货地址', trigger: 'change' }]
      },
      order: '',
      extend1: ''
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.buttonloading = true
          const params = { ...this.form }
          delete params.harvestaddress
          params.province = this.form.harvestaddress[0]
          params.city = this.form.harvestaddress[1]
          params.district = this.form.harvestaddress[2]
          this.$http.post('/dig/order/submit', params).then(res => {
            if (res.status === 200) {
              this.$notification['success']({
                message: '成功',
                description: res.message,
                duration: 2
              })
              this.order = res.body.orderId
              this.extend1 = res.body.extend1
            } else if (res.status === 400) {
              this.$notification['error']({
                message: '错误',
                description: res.message,
                duration: 4
              })
            }
            this.buttonloading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
      this.form.harvestaddress = undefined
      this.order = ''
      this.extend1 = ''
    }
  }
}
</script>

<style scoped>
.wrap {
  padding-top: 30px;
}
</style>
